import { Dictionary } from "@types";
import { PageID, TabID } from "codes/page";
import { get, isArray, isString, replace } from "lodash";

type PathKey = [PageID, TabID?] | PageID;
const makePathKey = (arg: PathKey): string => {
  if (isArray(arg)) {
    const tabStr = arg[1] !== undefined ? "/" + String(arg[1]) : "";
    return String(arg[0]) + tabStr;
  } else {
    return String(arg);
  }
};

const getPageId = (key: PathKey): PageID => {
  if (isArray(key)) {
    return key[0];
  } else {
    return key;
  }
};

const getTabId = (key: PathKey): TabID => {
  if (isArray(key)) {
    return key[1] ?? TabID.NONE;
  } else {
    return TabID.NONE;
  }
};

const PATHS = {
  [makePathKey(PageID.NONE)]: "/",

  // 認証
  [makePathKey(PageID.LOGIN)]: "/login",
  [makePathKey(PageID.LOGOUT)]: "/logout",
  [makePathKey(PageID.CLEAR_CHANGE_CONTRACT)]: "/clear-change-contract",

  [makePathKey(PageID.DASHBOARD_OVERVIEW)]: "/dashboard",

  // APP
  [makePathKey(PageID.APP_RECEIPT_ISSUING_ORDER_INDEX)]:
    "/app/receipt-issuing-order/:token",
  [makePathKey(PageID.APP_RECEIPT_ISSUING_ORDER_MAIL_ORDER)]:
    "/app/receipt-issuing-order/mail",
  [makePathKey(PageID.APP_RECEIPT_ISSUING_ORDER_EMAIL_ORDER)]:
    "/app/receipt-issuing-order/email",
  [makePathKey(PageID.APP_PRIVACY_POLICY)]: "/app/privacy-policy",

  // 契約関連
  [makePathKey(PageID.DASHBOARD_CONTRACT_LIST)]:
    "/dashboard/contract/list/:page",
  [makePathKey(PageID.DASHBOARD_CONTRACT_DETAIL)]:
    "/dashboard/contract/detail/:id",
  [makePathKey(PageID.DASHBOARD_CONTRACT_CREATE)]: "/dashboard/contract/create",

  // 利用実績関連
  [makePathKey(PageID.DASHBOARD_USE_SUMMARY_LIST_BY_CONTRACT)]:
    "/dashboard/use-summary/list",

  // 領収証発行依頼関連
  [makePathKey(
    PageID.DASHBOARD_RECEIPT_ISSUING_ORDER_CREATE_CUSTOM_HELLO_TECHNO
  )]: "/dashboard/receipt-issusing-order/create",
  [makePathKey(
    PageID.DASHBOARD_RECEIPT_ISSUING_ORDER_LIST_CUSTOM_HELLO_TECHNO
  )]: "/dashboard/receipt-issusing-order/list/:page",
  [makePathKey(
    PageID.DASHBOARD_RECEIPT_ISSUING_ORDER_DETAIL_CUSTOM_HELLO_TECHNO
  )]: "/dashboard/receipt-issusing-order/detail/:id",

  //　利用実績関連
  [makePathKey(PageID.DASHBOARD_USE_SUMMARY_LIST_CUSTOM_HELLO_TECHNO)]:
    "/dashboard/use-summary/hello-techno/list",
  [makePathKey(PageID.DASHBOARD_USE_SUMMARY_DETAIL_CUSTOM_HELLO_TECHNO)]:
    "/dashboard/use-summary/hello-techno/detail",

  // ログインユーザ管理
  [makePathKey(PageID.DASHBOARD_LOGIN_USER_LIST)]:
    "/dashboard/login-user/list/:page",
  [makePathKey(PageID.DASHBOARD_LOGIN_USER_CREATE)]:
    "/dashboard/login-user/create",
  [makePathKey(PageID.DASHBOARD_LOGIN_USER_CHANGE_PASSWORD)]:
    "/dashboard/login-user/change-password/:id",

  // 領収証リクエスト
  [makePathKey(PageID.RECEIPT_REQUEST)]: "/request/receipt/:contractId",

  // その他
  [makePathKey(PageID.PAGE_403)]: "403",
  [makePathKey(PageID.PAGE_404)]: "404",
};

export type PathOption = {
  page?: number;
  query?: Dictionary;
};
export function getPath(key: PathKey, option?: PathOption) {
  const pageId = getPageId(key);
  const tabId = getTabId(key);

  let path = getRoute(pageId);

  // ページ番号解決
  path = replacePathParam(path, "page", option?.page ?? 0);

  // その他URLパラメータ変換
  if (option?.query !== undefined) {
    Object.keys(option.query).forEach((key) => {
      const value = get(option.query, key);
      if (value === undefined) return;

      path = replacePathParam(path, key, value);
    });
  }

  return path;
}

export function getListPagePath(key: PathKey, page: number): string {
  return getPath(key, { page });
}

export function getRoute(key: PathKey, exclude?: string): string {
  let path = get(PATHS, makePathKey(key));
  if (!path) throw new Error("ルート未定義:" + makePathKey(key));

  if (exclude) {
    path = replace(path, "/" + exclude + "/", "");
  }

  return path;
}

function replacePathParam(
  sourceStr: string,
  searchStr: string,
  replacement: string | number
): string {
  return replace(
    sourceStr,
    ":" + searchStr,
    isString(replacement) ? replacement : String(replacement)
  );
}
