import { csrfToken } from "api/auth";
import { memo, useEffect, useState } from "react";

function CsrfTokenProvider() {
  const [done, setDone] = useState(false);

  useEffect(() => {
    if (!done) {
      setDone(true);
      csrfToken();
    }
  }, []);

  return null;
}

export default memo(CsrfTokenProvider);
