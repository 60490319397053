import { format, isValid, parse, parseISO } from "date-fns";

export const DEFAULT_DATE_FORMAT = "yyyy/MM/dd";
export const DEFAULT_DATE_TIME_FORMAT = "yyyy/MM/dd HH:mm:ss";
export const DEFAULT_DATE_TIME_FORMAT_ANOTHER1 = "yyyy-MM-dd HH:mm:ss";
export const DEFAULT_YYYYMM_FORMAT = "yyyyMM";

type Input = Date | string | null | undefined;

export const formatDateStr = (source: Input) => {
  return formatToStr(source, DEFAULT_DATE_FORMAT);
};

export const formatDateTimeStr = (source: Date | string | null | undefined) => {
  return formatToStr(source, DEFAULT_DATE_TIME_FORMAT);
};
export const formatYYYYMMStr = (source: Date | string | null | undefined) => {
  return formatToStr(source, DEFAULT_YYYYMM_FORMAT);
};

const formatToStr = (source: Input, formatStr: string) => {
  if (source === null || source === undefined) return "";
  if (source instanceof Date) return format(source, formatStr);
  return format(parseISO(source), formatStr);
};

export const now = () => {
  return new Date();
};
export const nowStr = (): string => {
  return formatDateTimeStr(now());
};

export const dateParse = (source: Input): Date | null => {
  return parseFromFormat(source, DEFAULT_DATE_FORMAT);
};

export const dateTimeParse = (source: Input): Date | null => {
  return (
    parseFromFormat(source, DEFAULT_DATE_TIME_FORMAT) ??
    parseFromFormat(source, DEFAULT_DATE_TIME_FORMAT_ANOTHER1)
  );
};

const parseFromFormat = (source: Input, format: string): Date | null => {
  if (source === null || source === undefined) return null;
  if (source instanceof Date) return source;

  const ret = parse(source, format, new Date());
  if (isValid(ret)) {
    return ret;
  }
  return null;
};
