import { HasChildren } from "@types";
import { PageID, TabID } from "codes/page";
import usePage from "hooks/usePage";
import useResponsive from "hooks/useResponsive";
import useWindowSize from "hooks/useWindowSize";
import { ReactNode, createContext, useMemo, useState } from "react";

type ContextProps = {
  headerTitle: string;
  setHeaderTitle: (title: string) => void;
  drawerWidth: number;
  innerHeight: number;
  innerWidth: number;
  contentsWidth: number;
  tabs: ReactNode | null;
  setTabs: (tabs: ReactNode | null) => void;
  pageId: PageID;
  setPageId: (pageId: PageID) => void;
  tabId: TabID;
  setTabId: (tabId: TabID) => void;

  showDrawer: boolean;
  overBreakPoint: boolean;
};
const contextInit: ContextProps = {
  headerTitle: "",
  setHeaderTitle: (title: string) => {},
  drawerWidth: 0,
  innerHeight: 0,
  innerWidth: 0,
  contentsWidth: 0,
  tabs: null,
  setTabs: (tabs: ReactNode | null) => {},
  pageId: PageID.NONE,
  setPageId: (pageId: PageID) => {},
  tabId: TabID.NONE,
  setTabId: (tabId: TabID) => {},

  showDrawer: false,
  overBreakPoint: false,
};
export const DashboardLayoutContext = createContext(contextInit);

type Props = HasChildren;
export function DashboardLayoutContextProvider({ children }: Props) {
  const drawerWidth = 256;

  const [headerTitle, setHeaderTitle] = useState("");
  const [tabs, setTabs] = useState<ReactNode | null>(null);

  const { width: innerWidth, height: innerHeight } = useWindowSize();
  const { pageId, setPageId, tabId, setTabId } = usePage();

  const overBreakPoint = !!useResponsive("up", "sm");

  const showDrawer = useMemo(() => overBreakPoint, [overBreakPoint]);

  const contentsWidth = useMemo(() => {
    return innerWidth - (showDrawer ? drawerWidth : 0);
  }, [drawerWidth, innerWidth, showDrawer]);

  return (
    <DashboardLayoutContext.Provider
      value={{
        headerTitle,
        setHeaderTitle,
        drawerWidth,
        innerWidth,
        innerHeight,
        contentsWidth,
        tabs,
        setTabs,
        pageId,
        setPageId,
        tabId,
        setTabId,

        showDrawer,
        overBreakPoint,
      }}
    >
      {children}
    </DashboardLayoutContext.Provider>
  );
}
