import { CustomCode as C } from "codes/custom";
import { PageID as P } from "codes/page";
import { UserRole as R } from "codes/user";

type AuthConfiguration = {
  role: R[];
  custom: C[];

  allowChangedContract?: boolean;
};
export const AUTH = {
  [P.NONE]: setAuth("all"),
  [P.LOGIN]: setAuth("all"),
  [P.LOGOUT]: setAuth("all"),
  [P.CLEAR_CHANGE_CONTRACT]: setAuth("eq", R.SUPER_ADMIN, {
    allowChangedContract: true,
  }),

  [P.DASHBOARD_OVERVIEW]: setAuth("ge", R.NORMAL_ADMIN),

  [P.DASHBOARD_CONTRACT_LIST]: setAuth("eq", R.SUPER_ADMIN, {
    allowChangedContract: false,
  }),
  [P.DASHBOARD_CONTRACT_DETAIL]: setAuth("eq", R.SUPER_ADMIN, {
    allowChangedContract: false,
  }),
  [P.DASHBOARD_CONTRACT_CREATE]: setAuth("eq", R.SUPER_ADMIN, {
    allowChangedContract: false,
  }),

  [P.DASHBOARD_USE_SUMMARY_LIST_BY_CONTRACT]: setAuth("eq", R.SUPER_ADMIN, {
    allowChangedContract: false,
  }),

  [P.DASHBOARD_RECEIPT_ISSUING_ORDER_CREATE_CUSTOM_HELLO_TECHNO]: setAuth(
    "ge",
    R.NORMAL_ADMIN,
    { custom: [C.HELLO_TECHNO] }
  ),
  [P.DASHBOARD_RECEIPT_ISSUING_ORDER_LIST_CUSTOM_HELLO_TECHNO]: setAuth(
    "ge",
    R.NORMAL_ADMIN,
    { custom: [C.HELLO_TECHNO] }
  ),
  [P.DASHBOARD_RECEIPT_ISSUING_ORDER_DETAIL_CUSTOM_HELLO_TECHNO]: setAuth(
    "ge",
    R.NORMAL_ADMIN,
    { custom: [C.HELLO_TECHNO] }
  ),
  [P.DASHBOARD_USE_SUMMARY_LIST_CUSTOM_HELLO_TECHNO]: setAuth(
    "ge",
    R.NORMAL_ADMIN,
    { custom: [C.HELLO_TECHNO] }
  ),
  [P.DASHBOARD_USE_SUMMARY_DETAIL_CUSTOM_HELLO_TECHNO]: setAuth(
    "ge",
    R.NORMAL_ADMIN,
    { custom: [C.HELLO_TECHNO] }
  ),
  [P.DASHBOARD_LOGIN_USER_LIST]: setAuth("ge", R.CONTRACT_ADMIN, {
    allowChangedContract: true,
  }),
  [P.DASHBOARD_LOGIN_USER_CREATE]: setAuth("ge", R.CONTRACT_ADMIN, {
    allowChangedContract: true,
  }),
  [P.DASHBOARD_LOGIN_USER_CHANGE_PASSWORD]: setAuth("ge", R.NORMAL_ADMIN),

  [P.PAGE_403]: setAuth("all"),
  [P.PAGE_404]: setAuth("all"),
};

type Target = "ge" | "le" | "eq" | "all";
type UserRoleKey = keyof typeof R;
type OptionProps = {
  custom?: C[];
  allowChangedContract?: boolean;
};
function setAuth(
  target: Target,
  targetRole?: R,
  option?: OptionProps
): AuthConfiguration {
  const roles: R[] = [];

  for (const key in R) {
    const role = R[key as UserRoleKey];

    if (target === "all") {
      roles.push(role);
      continue;
    }

    if (targetRole === undefined) {
      continue;
    }

    if (target === "ge" && role >= targetRole) {
      roles.push(role);
      continue;
    }
    if (target === "le" && role <= targetRole) {
      roles.push(role);
      continue;
    }
    if (target === "eq" && role === targetRole) {
      roles.push(role);
      continue;
    }
  }

  return {
    role: roles,
    custom: option?.custom ?? [],
    allowChangedContract: option?.allowChangedContract,
  };
}
