function getEnv() {
  const e = process.env.REACT_APP_ENV;
  if (e === "local") return e;
  if (e === "staging") return e;
  if (e === "production") return e;
  return "other";
}

export const APP_ENV: "local" | "staging" | "production" | "other" = getEnv();
// API
// ----------------------------------------------------------------------
export const HOST_API =
  process.env.REACT_APP_HOST_API_KEY || window.location.origin || "";
