import { Backdrop, CircularProgress, useTheme } from "@mui/material";
import { createContext, useState } from "react";

type Props = {
  children: React.ReactNode;
};

type ContextProps = {
  showBackDrop: boolean;
  setShowBackDrop: (show: boolean) => void;
};
const defaultProps: ContextProps = {
  showBackDrop: false,
  setShowBackDrop: (show: boolean) => {},
};
export const BackDroptContext = createContext<ContextProps>(defaultProps);

export default function BackDropContextProvider({ children }: Props) {
  const [showBackDrop, setShowBackDrop] = useState(false);
  return (
    <BackDroptContext.Provider value={{ showBackDrop, setShowBackDrop }}>
      {children}
      <Backdrop
        open={showBackDrop}
        sx={{
          // display: 'frex',
          zIndex: 9999,
          opacity: "0.3 !important",
        }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </BackDroptContext.Provider>
  );
}
