import { useLocation, useNavigate } from "react-router";
import { Dictionary } from "@types";
import { PageID } from "codes/page";
import { getPath } from "routes/path";
import { scrollToTop } from "utils/page";

export default function useNavigateCustom() {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  const navigateWhenChanged = (
    path: string,
    param?: Dictionary | URLSearchParams | string,
    option?: {
      reload?: boolean;
      context?: any;
    }
  ) => {
    const currentUrl = pathname + search;
    let newPath = path;

    if (typeof param === "string") {
      if (!param.startsWith("?")) {
        newPath += "?";
      }
      newPath += param;
    } else if (param instanceof URLSearchParams) {
      const search = param.toString();
      if (search) {
        newPath += "?";
        newPath += search;
      }
    } else if (typeof param === "object") {
      const urlParam = new URLSearchParams(param);
      const search = urlParam.toString();
      if (search) {
        newPath += "?";
        newPath += search;
      }
    }

    if (currentUrl !== newPath || option?.reload) {
      if (option?.context) {
        console.log("navigate to", newPath, option.context);
      }

      // 同じURLで遷移要求があった場合、reload設定されていれば同じページを読み込みなおす
      // 一旦、空白のページを経由する必要がある
      if (currentUrl === newPath && option?.reload) {
        navigate(getPath(PageID.NONE));
        setTimeout(() => {
          navigate(newPath);
        }, 50);
      } else {
        navigate(newPath);
      }
    }

    scrollToTop();
  };
  return { navigate, navigateWhenChanged };
}
