// form
import { useFormContext, Controller } from "react-hook-form";
// @mui
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormControlLabelProps,
} from "@mui/material";
import { useMemo } from "react";

// ----------------------------------------------------------------------

interface RHFCheckboxProps extends Omit<FormControlLabelProps, "control"> {
  name: string;
  readOnly?: boolean;
}

export function RHFCheckbox({ name, readOnly, ...other }: RHFCheckboxProps) {
  const { control, watch } = useFormContext();

  const _formValue = watch(name);

  //   const formValue : boolean = useMemo(()=>{
  // if(_formValue typeof 'boolean') {
  //   return _formValue;
  // }

  // return false
  //   },[_formValue])

  const formValue = useMemo(() => {
    if (typeof _formValue === "boolean") {
      return _formValue;
    }
    if (typeof _formValue === "string") {
      return _formValue === "1" || _formValue === "true";
    }

    console.log("else");
    return false;
  }, [_formValue]);

  if (readOnly) {
    return (
      <FormControlLabel
        control={
          <Checkbox
            disableRipple
            disableTouchRipple
            disableFocusRipple
            checked={formValue}
            sx={{
              cursor: "default",
            }}
          />
        }
        sx={{
          cursor: "default",
        }}
        {...other}
      />
    );
  }

  return (
    <FormControlLabel
      control={
        <Controller
          name={name}
          control={control}
          render={({ field }) => <Checkbox {...field} checked={formValue} />}
        />
      }
      {...other}
    />
  );
}

// ----------------------------------------------------------------------

interface RHFMultiCheckboxProps
  extends Omit<FormControlLabelProps, "control" | "label"> {
  name: string;
  options: {
    label: string;
    value: any;
  }[];
}

export function RHFMultiCheckbox({
  name,
  options,
  ...other
}: RHFMultiCheckboxProps) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        const onSelected = (option: string) =>
          field.value.includes(option)
            ? field.value.filter((value: string) => value !== option)
            : [...field.value, option];

        return (
          <FormGroup>
            {options.map((option) => (
              <FormControlLabel
                key={option.value}
                control={
                  <Checkbox
                    checked={field.value.includes(option.value)}
                    onChange={() => field.onChange(onSelected(option.value))}
                  />
                }
                label={option.label}
                {...other}
              />
            ))}
          </FormGroup>
        );
      }}
    />
  );
}
