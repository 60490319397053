import { Dictionary } from "@types";

export * from "./RHFCheckbox";

export { default as FormProvider } from "./FormProvider";

export { default as RHFSwitch } from "./RHFSwitch";
export { default as RHFSelect } from "./RHFSelect";
export { default as RHFTextField } from "./RHFTextField";
export { default as RHFRadioGroup } from "./RHFRadioGroup";
export { default as RHFAutoComplete } from "./RHFAutoComplete";

/**
 *
 * @param formData object
 * @param setter RHFの関数setError
 * @param messages Dictionary
 */
export function setFormErrorMessages(
  formData: object,
  setter: any,
  messages: Dictionary
) {
  let count = 0;
  const keys = Object.keys(formData);
  Object.keys(messages).forEach((name) => {
    if (keys.includes(name)) {
      setter(name, { message: messages[name] });
      count++;
    }
  });
  return count;
}
