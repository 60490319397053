import { PageID } from "codes/page";
import SimpleLayout from "layouts/simple";
import { lazy, useMemo } from "react";
import { RouteObject } from "react-router-dom";
import { Loadable } from "routes";
import { getRoute } from "routes/path";

export default function CommonRoutes(): RouteObject {
  const PrivacyPolicy = Loadable(
    lazy(() => import("pages/common/PrivacyPolicy"))
  );
  const children: RouteObject[] = useMemo(() => {
    return [
      {
        path: getRoute(PageID.APP_PRIVACY_POLICY),
        element: <PrivacyPolicy />,
      },
    ];
  }, []);

  return {
    element: <SimpleLayout />,
    children,
  };
}
