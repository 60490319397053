import { HasChildren } from "@types";
import { CheckTokenRequest, CheckTokenResponse, checkToken } from "api/app";
import { AppReceiptIssuingOrder } from "api/app/receipt-issuing-order";
import { PageID } from "codes/page";
import useAPICall from "hooks/useAPICall";
import useNavigateCustom from "hooks/useNavigateCustom";
import { createContext, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getPath } from "routes/path";
import { StoreId, getStore, setStore } from "storage/localstorage";

type App = {
  token: string;
  tokenResult: "cheking" | "ok" | "ng";
  receiptIssuingOrder: AppReceiptIssuingOrder | null;
  setToken: (token: string) => void;
  fetch: VoidFunction;
  navigateToHome: VoidFunction;
};

export const AppContext = createContext<App>({
  token: "",
  tokenResult: "cheking",
  receiptIssuingOrder: null,
  setToken: (token: string) => {},
  fetch: () => {},
  navigateToHome: () => {},
});

type Props = HasChildren;
export function AppContextProvider({ children }: Props) {
  const { navigateWhenChanged } = useNavigateCustom();

  const { token: paramToken } = useParams();

  const [token, _setToken] = useState("");

  const [receiptIssuingOrder, setReceiptIssuingOrder] =
    useState<AppReceiptIssuingOrder | null>(null);

  const [tokenResult, setTokenResult] = useState<"cheking" | "ok" | "ng">(
    "cheking"
  );

  const handleSuccess = useCallback(
    (res: CheckTokenResponse, sendData: CheckTokenRequest) => {
      if (receiptIssuingOrder === null) {
        setTokenResult("ok");
        _setToken(sendData.access_token);
        setStore(StoreId.ACCESS_TOKEN, sendData.access_token);
      }

      // 再Fetch用　タイムスタンプが違う場合のみ取り込む
      const lastTimestamp = receiptIssuingOrder?.updated_at ?? "";
      const currentTimestamp = res.data.receipt_issuing_order.updated_at;
      if (lastTimestamp !== currentTimestamp) {
        console.log("発行依頼情報読込", { lastTimestamp, currentTimestamp });
        setReceiptIssuingOrder(res.data.receipt_issuing_order);
      }
    },
    [receiptIssuingOrder]
  );

  const checkTokenAPI = useAPICall({
    apiMethod: checkToken,
    onSuccess: handleSuccess,
    onFailed: () => {
      setTokenResult("ng");
      navigateWhenChanged(getPath(PageID.PAGE_404));
    },
  });

  const setToken = (token: string) => {
    checkTokenAPI.callAPI({
      access_token: token,
    });
  };

  const fetch = () => {
    if (token) {
      setToken(token);
    }
  };

  const navigateToHome = () => {
    const path = getPath(PageID.APP_RECEIPT_ISSUING_ORDER_INDEX, {
      query: { token: token },
    });
    navigateWhenChanged(path);
  };

  useEffect(() => {
    const storeToken = getStore(StoreId.ACCESS_TOKEN);

    if (paramToken && !token) {
      setToken(paramToken);
    } else if (storeToken && !token) {
      setToken(storeToken);
    } else if (!token && !paramToken) {
      setTokenResult("ng");
      navigateWhenChanged(getPath(PageID.PAGE_404));
    }
  }, [paramToken]);

  // 定期的にFetchする
  // 排他エラーとなる確率を下げるため
  useEffect(() => {
    if (token) {
      const interval = setInterval(() => {
        fetch();
      }, 5 * 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [token, handleSuccess]);

  return (
    <AppContext.Provider
      value={{
        token,
        tokenResult,
        receiptIssuingOrder,
        setToken,
        fetch,
        navigateToHome,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}
