import { PageID } from "codes/page";
import { AppContextProvider } from "contexts/AppContext";
import SimpleLayout from "layouts/simple";
import { lazy, useMemo } from "react";
import { RouteObject } from "react-router-dom";
import { Loadable } from "routes";
import { getRoute } from "routes/path";

export default function AppRoutes(): RouteObject {
  const ReceiptIssuingOrder = Loadable(
    lazy(() => import("pages/app/ReceiptIssuingOrder"))
  );
  const MailOrder = Loadable(lazy(() => import("pages/app/MailOrder")));
  const EmailOrder = Loadable(lazy(() => import("pages/app/EmailOrder")));

  const children: RouteObject[] = useMemo(() => {
    return [
      {
        path: getRoute(PageID.APP_RECEIPT_ISSUING_ORDER_INDEX),
        element: <ReceiptIssuingOrder />,
      },
      {
        path: getRoute(PageID.APP_RECEIPT_ISSUING_ORDER_MAIL_ORDER),
        element: <MailOrder />,
      },
      {
        path: getRoute(PageID.APP_RECEIPT_ISSUING_ORDER_EMAIL_ORDER),
        element: <EmailOrder />,
      },
    ];
  }, []);

  return {
    element: (
      <AppContextProvider>
        <SimpleLayout />
      </AppContextProvider>
    ),
    children,
  };
}
