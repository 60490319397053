import { APICommonResponse, ApiId, HttpMethod, request } from "..";
import { getUrl } from "../url";
import { AppReceiptIssuingOrder } from "./receipt-issuing-order";

export type CheckTokenRequest = {
  access_token: string;
};

export type CheckTokenResponse = {
  data: {
    receipt_issuing_order: AppReceiptIssuingOrder;
  };
} & APICommonResponse;
export const checkToken = async (data: CheckTokenRequest) => {
  const res = await request<CheckTokenResponse>({
    url: getUrl(ApiId.APP_TOKEN_CHECK),
    method: HttpMethod.GET,
    data: new URLSearchParams(data),
  });
  return res;
};
