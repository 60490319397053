let id = 0;
export const StoreId = {
  LOGIN_ROUTE: "LOGIN_ROUTE",

  // APP向け
  ACCESS_TOKEN: "ACCESS_TOKEN",
} as const;
export type StoreId = (typeof StoreId)[keyof typeof StoreId];

export const getStore = (id: StoreId): string | null => {
  return localStorage.getItem(id);
};
export const setStore = (id: StoreId, data: any) => {
  localStorage.setItem(id, data);
};
export const removeStore = (id: StoreId) => {
  localStorage.removeItem(id);
};
export const clearStore = () => {
  localStorage.clear();
};
