import { useFormContext, Controller } from "react-hook-form";
import {
  Box,
  Chip,
  FormControl,
  FormHelperText,
  IconButton,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import TextFieldEx from "../form/TextFieldEx";
import { Dictionary } from "@types";
import { Clear, Label } from "@mui/icons-material";

// ----------------------------------------------------------------------

export type SelectOptionProps = {
  value: string;
  label?: string;
};

export const makeOptions = (list: Dictionary[]): SelectOptionProps[] => {
  const ret: SelectOptionProps[] = [];
  list.forEach((dic) => {
    const value = Object.keys(dic)[0];
    const label = dic[value];
    ret.push({
      value,
      label,
    });
  });
  return ret;
};

type IProps = {
  name: string;
  children?: any;
  readOnly?: boolean;
  options?: SelectOptionProps[];
  onFix?: VoidFunction;
};

type Props = IProps & TextFieldProps;
export type RHFSelectProps = Props;

export default function RHFSelect({
  name,
  children,
  readOnly,
  options,
  onFix,
  ...other
}: Props) {
  const { control, watch } = useFormContext();

  const formValue: string = watch(name);
  const [isMounted, setIsMounted] = useState(false);

  const getOptionElements = useCallback(() => {
    if (options) {
      if (options.length === 0) {
        return [
          <MenuItem key="disabled" disabled>
            候補がありません
          </MenuItem>,
        ];
      } else {
        return options.map((option, index) => {
          return (
            <MenuItem value={option.value} key={index}>
              {option.label ?? option.value}
            </MenuItem>
          );
        });
      }
    }
    return [];
  }, [options]);

  const getLabel = useMemo(() => {
    if (!options) return "";
    return (
      options.find((option) => {
        return option.value === formValue;
      })?.label ?? " "
    );
  }, [formValue, options]);

  useEffect(() => {
    if (isMounted && onFix) {
      onFix();
    }
    setIsMounted(true);
  }, [formValue]);

  if (readOnly) {
    return (
      <TextFieldEx readOnly {...other} value={getLabel} variant="standard" />
    );
  }

  if (!options) {
    return null;
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          select
          fullWidth
          error={!!error}
          helperText={error?.message}
          {...other}
          InputLabelProps={{ shrink: true }}
          SelectProps={{}}
        >
          {children}
          {getOptionElements()}
        </TextField>
      )}
    />
  );
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export function RHFSelectMuiliple({
  name,
  children,
  readOnly,
  options,
  onFix,
  ...other
}: Props) {
  const { control, watch, setValue } = useFormContext();

  const formValue: string[] = watch(name);

  const getOptionElements = useCallback(() => {
    if (options) {
      if (options.length === 0) {
        return [
          <MenuItem key="disabled" disabled>
            候補がありません
          </MenuItem>,
        ];
      } else {
        return options.map((option, index) => {
          return (
            <MenuItem value={option.value} key={index}>
              {option.label ?? option.value}
            </MenuItem>
          );
        });
      }
    }
    return [];
  }, [options]);

  const clearButton = useMemo(() => {
    if (formValue.length === 0) return null;

    const handleClick = () => {
      setValue(name, []);
    };

    return (
      <IconButton sx={{ mr: 1 }} onClick={handleClick}>
        <Clear />
      </IconButton>
    );
  }, [formValue]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <FormControl>
          <Select
            {...field}
            size="small"
            multiple
            value={formValue}
            error={!!error}
            input={<OutlinedInput endAdornment={clearButton} />}
            renderValue={(selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip key={value} label={value} size="small" />
                ))}
              </Box>
            )}
            MenuProps={MenuProps}
          >
            {children}
            {getOptionElements()}
          </Select>
          {!!error?.message && <FormHelperText>{error.message}</FormHelperText>}
        </FormControl>
      )}
    />
  );
}
