import LoadingScreen from "components/LoadingScreen";
import useAuth from "hooks/useAuth";
import { ElementType, Suspense, lazy } from "react";
import { useRoutes } from "react-router-dom";
import AppRoutes from "./sub/app";
import AuthRoutes from "./sub/auth";
import CommonRoutes from "./sub/common";
import DashboardRoutes from "./sub/dashboard";
import RequestReceiptRoutes from "./sub/receipt-request";

export const Loadable = (Component: ElementType) => (props: any) => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );
};

export function Routes() {
  const { initialized } = useAuth();
  return useRoutes([
    CommonRoutes(),
    AuthRoutes(),
    AppRoutes(),
    DashboardRoutes(),
    RequestReceiptRoutes(),
    {
      path: "*",
      element: initialized ? <Page404 /> : <LoadingScreen />,
    },
  ]);
}

const Page404 = Loadable(lazy(() => import("pages/common/Page404")));
