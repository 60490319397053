import { PageID } from "codes/page";
import SimpleLayout from "layouts/simple";
import { lazy, useMemo } from "react";
import { RouteObject } from "react-router-dom";
import { Loadable } from "routes";
import { getRoute } from "routes/path";

export default function ReceiptRequestRoutes(): RouteObject {
  const ReceiptRequest = Loadable(lazy(() => import("pages/receipt-request")));
  const children: RouteObject[] = useMemo(() => {
    return [
      {
        path: getRoute(PageID.RECEIPT_REQUEST),
        element: <ReceiptRequest />,
      },
    ];
  }, []);

  return {
    element: <SimpleLayout />,
    children,
  };
}
