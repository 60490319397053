import { HasChildren } from "@types";
import { createContext, useLayoutEffect, useState } from "react";

export const WindowSizeContext = createContext({
  width: 0,
  height: 0,
});

type Props = HasChildren;
export function WindowSizeContextProvider({ children }: Props) {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  useLayoutEffect(() => {
    const updateSize = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    };

    window.addEventListener("resize", updateSize);
    updateSize();

    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return (
    <WindowSizeContext.Provider value={{ width, height }}>
      {children}
    </WindowSizeContext.Provider>
  );
}
