import { useFormContext, Controller } from "react-hook-form";
import { TextField, TextFieldProps } from "@mui/material";
import { useMemo } from "react";
import { formatDateStr } from "utils/datetime";
import TextFieldEx from "../form/TextFieldEx";

// ----------------------------------------------------------------------

type IProps = {
  name: string;
  readOnly?: boolean;
};

type Props = IProps & TextFieldProps;
export type RHFTextFieldProps = Props;

export default function RHFTextField({
  name,
  readOnly,
  size: fieldSize = "small",
  ...other
}: Props) {
  const { control, watch } = useFormContext();

  const value = watch(name);

  const valueStr = useMemo(() => {
    if (typeof value === "string") {
      if (value === "") {
        return " ";
      } else {
        return value;
      }
    }
    if (value instanceof Date) {
      return formatDateStr(value);
    }
    if (readOnly) {
      return " ";
    }
    return "";
  }, [value]);

  if (readOnly) {
    return (
      <TextFieldEx readOnly {...other} value={valueStr} variant="standard" />
    );
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          fullWidth
          error={!!error}
          helperText={error?.message}
          {...other}
          size={fieldSize}
        />
      )}
    />
  );
}
