import { PageID } from "codes/page";
import SimpleLayout from "layouts/simple";
import { lazy, useMemo } from "react";
import { RouteObject } from "react-router-dom";
import { Loadable } from "routes";
import { getRoute } from "routes/path";

export default function AuthRoutes(): RouteObject {
  const Login = Loadable(lazy(() => import("pages/auth/login")));
  const Logout = Loadable(lazy(() => import("pages/auth/logout")));
  const ClearContract = Loadable(
    lazy(() => import("pages/auth/clear-contract"))
  );

  const children: RouteObject[] = useMemo(() => {
    // 認証関連 -------------------------------
    return [
      {
        path: getRoute(PageID.LOGIN),
        element: <Login />,
      },
      {
        path: getRoute(PageID.LOGOUT),
        element: <Logout />,
      },
      {
        path: getRoute(PageID.CLEAR_CHANGE_CONTRACT),
        element: <ClearContract />,
      },
    ];
  }, []);

  return {
    element: <SimpleLayout />,
    children,
  };
}
