import { AxiosError, AxiosResponse } from "axios";
import { format } from "date-fns";
import { Dictionary, get } from "lodash";
import { DataUrl } from "@types";
import { setFormErrorMessages } from "components/hook-form";
import axios from "utils/axios";

let id = 0;
export const ApiId = {
  // 共通---------------------------------------
  CSRF_TOKEN: id++,
  ME: id++,

  LOGIN: id++,
  LOGOUT: id++,

  // APP向け------------------------------------
  APP_TOKEN_CHECK: id++,
  RECEIPT_ISSUING_ORDER_ACCEPT_POLICIES: id++,
  RECEIPT_ISSUING_ORDER_CONFIRM: id++,
  DOWNLOAD_RECEIPT: id++,
  RECEIPT_ISSUING_ORDER_MAIL_ORDER: id++,
  RECEIPT_ISSUING_ORDER_EMAIL_ORDER: id++,

  // DASHBOARD向け-------------------------------
  CHANGE_CONTRACT: id++,

  RECEIPT_ISSUING_ORDERS: id++,
  RECEIPT_ISSUING_ORDER: id++,
  RECEIPT_ISSUING_ORDER_CREATE: id++,
  RECEIPT_ISSUING_ORDER_MAIL_POST_COMPLETE: id++,
  RECEIPT_ISSUING_ORDER_HANDELRS: id++,
  RECEIPT_ISSUING_ORDER_CHANGE_HANDELR: id++,
  RECEIPT_ISSUING_ORDER_STATUS_NAMES: id++,
  DOWNLOAD_RECEIPT_LETTER: id++,

  CONTRACTS: id++,
  CONTRACT_CREATE: id++,

  USE_SUMMARY_YYYYMM: id++,
  USE_SUMMARIES_BY_CONTRACT: id++,

  LOGIN_USERS: id++,
  LOGIN_USER_CREATE: id++,
  LOGIN_USER_CHANGE_PASSWORD: id++,

  // FOR CUSTOM
  HT_CUSTOM_CUSTOMERS: id++,
  HT_CUSTOM_PARKINGS: id++,
  HT_CUSTOM_PARKING_BY_CODE: id++,
  HT_CUSTOM_ADJUST_DATA: id++,
  HT_CUSTOM_RECEIPT_ISSUING_ORDERS: id++,
  HT_CUSTOM_RECEIPT_ISSUING_ORDER_CREATE: id++,
  HT_CUSTOM_USE_SUMMARIES: id++,
  HT_CUSTOM_USE_SUMMARY_DOWNLOAD_CSV: id++,
  HT_CUSTOM_DOWNLOAD_RECEIPT_LETTER: id++,
  HT_CUSTOM_DOWNLOAD_RECEIPT_LETTERS: id++,
} as const;
export type ApiId = (typeof ApiId)[keyof typeof ApiId];

export const HttpMethod = {
  GET: "get",
  POST: "post",
} as const;
export type HttpMethod = (typeof HttpMethod)[keyof typeof HttpMethod];

export const ResultCode = {
  SUCCESS: 0,
  FAILED: 1,
  UNAUTHORIZED: 2,
  EXCLUSIVE_ERROR: 3,
};
export type ResultCode = (typeof ResultCode)[keyof typeof ResultCode];

export interface TimestampRequest {
  timestamp: string;
}
export type ListRequest = {
  sort?: string;
  order?: string;
};

export interface APICommonResponse {
  result: ResultCode;
  messages: {
    errors?: Dictionary<string>;
    general?: string;
    email_id?: number;
  };
}
export type ImagesResponse = {
  data: {
    images: DataUrl[];
  };
} & APICommonResponse;

export const makeParam = <T extends object>(data: T): Dictionary<string> => {
  const res: Dictionary<string> = {};

  Object.keys(data).map((key) => {
    const val = get(data, key);
    if (typeof val === "string" && val.length !== 0) {
      res[key] = val;
    } else if (typeof val === "number") {
      res[key] = String(val);
    } else if (typeof val === "boolean") {
      res[key] = val ? "1" : "0";
    } else if (val instanceof Date) {
      res[key] = format(val, "yyyy-MM-dd");
    }
  });

  return res;
};

export const makeFormData = <T extends object>(data: T): FormData => {
  const res = new FormData();

  Object.keys(data).map((key) => {
    const val = get(data, key);
    if (typeof val === "string" && val.length !== 0) {
      res.append(key, val);
    } else if (typeof val === "number") {
      res.append(key, String(val));
    } else if (typeof val === "boolean") {
      res.append(key, val ? "1" : "0");
    } else if (val instanceof Date) {
      res.append(key, format(val, "yyyy-MM-dd"));
    } else if (val instanceof File) {
      res.append(key, val);
    } else if (Array.isArray(val)) {
      val.forEach((v) => {
        res.append(key + "[]", v);
      });
    } else {
      console.log("undefined data", key, val);
    }
  });

  return res;
};

const isAxiosError = (error: any): error is AxiosError => {
  return !!error.isAxiosError;
};

type RequestArgument = {
  url: string;
  method: HttpMethod;
  data?: URLSearchParams | FormData | object;
  multipart?: boolean;
};
export const request = async <T extends APICommonResponse>({
  url,
  method,
  data,
  multipart,
}: RequestArgument): Promise<T | null> => {
  let response: AxiosResponse<T | any> | null = null;
  if (data instanceof URLSearchParams) {
    data.sort();
  }
  try {
    if (multipart && data instanceof FormData) {
      response = await axios({
        url,
        method: "post",
        data,
        headers: {
          "content-type": "multipart/form-data",
        },
      });
      console.log("RESPONSE", url, "multipart", data, response?.data);
    } else if (method === HttpMethod.GET) {
      let searchUrl = url;

      if (data instanceof URLSearchParams) {
        searchUrl += "?" + data.toString();
      }
      response = await axios.get<T>(searchUrl);
      console.log(new Date(), "RESPONSE", searchUrl, method, response?.data);
    } else if (method === HttpMethod.POST) {
      response = await axios.post<T>(url, data);

      let sendData: Dictionary<String> = {};
      if (data instanceof URLSearchParams) {
        data.forEach((val, key) => {
          sendData[key] = val;
        });
      } else if (typeof data === "object" && !(data instanceof FormData)) {
        Object.keys(data).forEach((key) => {
          sendData[key] = get(data, key);
        });
      }
      console.log("RESPONSE", url, method, sendData, response?.data);
    } else {
      return null;
    }

    if (response && response.data.result === ResultCode.SUCCESS) {
      return response.data;
    }
    return response?.data;
  } catch (e) {
    if (isAxiosError(e)) {
      if (e.response?.status === 401 || e.response?.status === 419) {
        window.location.reload();
      }
    }
    if (typeof e === "object") {
      const message = get(e, "message");
      if (message === "Unauthenticated.") {
        console.log("401!!!");
        window.location.reload();
        return null;
      }
      if (message === "CSRF token mismatch.") {
        console.log("419!!!");
        window.location.reload();
        return null;
      }
      if (message === "Service Unavailable") {
        console.log("503!!!");
        window.location.reload();
        return null;
      }
    }
    throw e;
  }
};

export async function apiRequest<
  T extends APICommonResponse,
  U extends object
>({
  apiMethod,
  sendData,
  onSuccess,
  onFailed,
  onFinaly,
  errorSetter,
  setSending,
}: {
  apiMethod: (sendData: U) => Promise<T | null>;
  sendData: U;
  onSuccess?: (res: T, sendData: U) => void;
  onFailed?: (res: APICommonResponse | null) => void;
  onFinaly?: (res: APICommonResponse | null) => void;
  errorSetter?: any;
  setSending?: (sending: boolean) => void;
}) {
  if (setSending) {
    setSending(true);
  }
  try {
    const res = await apiMethod(sendData);
    if (setSending) {
      setSending(false);
    }

    if (res?.result === ResultCode.SUCCESS) {
      if (onSuccess) {
        onSuccess(res, sendData);
      }
    } else {
      if (res?.messages.errors) {
        if (errorSetter) {
          const errorCount = setFormErrorMessages(
            sendData,
            errorSetter,
            res.messages.errors
          );
          console.log("FormErrorCount", errorCount);
        }
      }
      if (onFailed) {
        onFailed(res);
      }
      if (onFinaly) {
        onFinaly(res);
      }
    }
    return res;
  } catch (e) {
    if (setSending) {
      setSending(false);
    }
    console.error(e);
    if (onFailed) {
      onFailed(null);
    }
    if (onFinaly) {
      onFinaly(null);
    }
    return null;
  }
}
