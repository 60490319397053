import React, { useMemo, useState } from 'react';
import { TextField, TextFieldProps } from '@mui/material';

export type TextFieldExProps = {
  readOnly?: boolean;
} & TextFieldProps;

const TextFieldEx = ({ readOnly, ...others }: TextFieldExProps) => {
  if (readOnly) {
    const props: any = {};
    if (typeof others.value === 'string' && others.value.length === 0) {
      props.value = ' ';
    }

    return (
      <TextField
        {...others}
        sx={{
          input: {
            WebkitTextFillColor: 'black !important',
          },
          textarea: {
            WebkitTextFillColor: 'black !important',
          },
        }}
        disabled
        variant="standard"
        {...props}
      />
    );
  }

  return <TextField {...others} />;
};

export default React.memo(TextFieldEx);
