import { CssBaseline } from "@mui/material";
import AuthContextProvider from "contexts/AuthContext";
import { PageContextProvider } from "contexts/PageContext";
import { WindowSizeContextProvider } from "contexts/WindowSizeContext";
import CsrfTokenProvider from "providers/CsrfTokenProvider";
import SnackbarProvider from "providers/SnackbarProvider";
import { BrowserRouter } from "react-router-dom";
import { Routes } from "routes";
import AppThemeProvider from "theme";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ja from "date-fns/locale/ja";
import BackDropContextProvider from "contexts/BackDropContext";

export default function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
      <AuthContextProvider>
        <PageContextProvider>
          <WindowSizeContextProvider>
            <BrowserRouter>
              <AppThemeProvider>
                <SnackbarProvider>
                  <BackDropContextProvider>
                    <CsrfTokenProvider />
                    <CssBaseline />
                    <Routes />
                  </BackDropContextProvider>
                </SnackbarProvider>
              </AppThemeProvider>
            </BrowserRouter>
          </WindowSizeContextProvider>
        </PageContextProvider>
      </AuthContextProvider>
    </LocalizationProvider>
  );
}
