import { UserRole } from "codes/user";
import { APICommonResponse, ApiId, HttpMethod, makeParam, request } from ".";
import { getUrl } from "./url";
import { CustomCode } from "codes/custom";

type MeResponse = {
  data: {
    id: string;
    contract_id: string | null;
    role: UserRole;
    name: string;
    custom?: CustomCode[];
    contract_name?: string;
  };
} & APICommonResponse;

export const csrfToken = async () => {
  await request({
    url: getUrl(ApiId.CSRF_TOKEN),
    method: HttpMethod.GET,
  });
};

export const me = async () => {
  const res = await request<MeResponse>({
    url: getUrl(ApiId.ME),
    method: HttpMethod.GET,
  });
  return res;
};

export const login = async (param: { email: string; password: string }) => {
  const res = await request<MeResponse>({
    url: getUrl(ApiId.LOGIN),
    method: HttpMethod.POST,
    data: param,
  });
  return res;
};

export const logout = async () => {
  const res = await request({
    url: getUrl(ApiId.LOGOUT),
    method: HttpMethod.GET,
  });
  return res;
};

// 成り代わり
export const changeContract = async (param: { contract_id: string | null }) => {
  const res = await request<MeResponse>({
    url: getUrl(ApiId.CHANGE_CONTRACT),
    method: HttpMethod.POST,
    data: makeParam(param),
  });
  return res;
};
