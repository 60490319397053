let id = 0;
export const PageID = {
  NONE: id++,

  LOGIN: id++,
  LOGOUT: id++,
  CLEAR_CHANGE_CONTRACT: id++,

  APP_RECEIPT_ISSUING_ORDER_INDEX: id++,
  APP_RECEIPT_ISSUING_ORDER_MAIL_ORDER: id++,
  APP_RECEIPT_ISSUING_ORDER_EMAIL_ORDER: id++,
  APP_PRIVACY_POLICY: id++,

  DASHBOARD_OVERVIEW: id++,

  DASHBOARD_CONTRACT_LIST: id++,
  DASHBOARD_CONTRACT_DETAIL: id++,
  DASHBOARD_CONTRACT_CREATE: id++,

  DASHBOARD_USE_SUMMARY_LIST_BY_CONTRACT: id++,

  DASHBOARD_RECEIPT_ISSUING_ORDER_CREATE_CUSTOM_HELLO_TECHNO: id++,
  DASHBOARD_RECEIPT_ISSUING_ORDER_LIST_CUSTOM_HELLO_TECHNO: id++,
  DASHBOARD_RECEIPT_ISSUING_ORDER_DETAIL_CUSTOM_HELLO_TECHNO: id++,

  DASHBOARD_USE_SUMMARY_LIST_CUSTOM_HELLO_TECHNO: id++,
  DASHBOARD_USE_SUMMARY_DETAIL_CUSTOM_HELLO_TECHNO: id++,

  DASHBOARD_LOGIN_USER_LIST: id++,
  DASHBOARD_LOGIN_USER_CREATE: id++,
  DASHBOARD_LOGIN_USER_CHANGE_PASSWORD: id++,

  RECEIPT_REQUEST: id++,

  PAGE_403: id++,
  PAGE_404: id++,
} as const;

export type PageID = (typeof PageID)[keyof typeof PageID];

id = 0;
export const TabID = {
  NONE: id++,
  A: id++,
} as const;

export type TabID = (typeof TabID)[keyof typeof TabID];
