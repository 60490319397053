import { HOST_API } from "config";
import { ApiId as A } from ".";

const urls = {
  [A.CSRF_TOKEN]: "sanctum/csrf-cookie",
  [A.ME]: "me",
  [A.LOGIN]: "login",
  [A.LOGOUT]: "logout",
  [A.CHANGE_CONTRACT]: "change-contract",

  [A.APP_TOKEN_CHECK]: "app-token-check",
  [A.RECEIPT_ISSUING_ORDER_ACCEPT_POLICIES]:
    "receipt-issuing-order/accept/policies",
  [A.RECEIPT_ISSUING_ORDER_CONFIRM]: "receipt-issuing-order/confirm",
  [A.DOWNLOAD_RECEIPT]: "receipt/download",
  [A.RECEIPT_ISSUING_ORDER_MAIL_ORDER]: "receipt-issuing-order/mail-order",
  [A.RECEIPT_ISSUING_ORDER_MAIL_POST_COMPLETE]:
    "receipt-issuing-order/mail-complete",
  [A.RECEIPT_ISSUING_ORDER_EMAIL_ORDER]: "receipt-issuing-order/email-order",
  [A.RECEIPT_ISSUING_ORDER_HANDELRS]: "receipt-issuing-order/handlers",
  [A.RECEIPT_ISSUING_ORDER_CHANGE_HANDELR]:
    "receipt-issuing-order/change-handler",
  [A.RECEIPT_ISSUING_ORDER_STATUS_NAMES]: "receipt-issuing-order/statuses",
  [A.DOWNLOAD_RECEIPT_LETTER]: "receipt-letter/download",

  [A.RECEIPT_ISSUING_ORDERS]: "receipt-issuing-orders",

  [A.CONTRACTS]: "contracts",
  [A.CONTRACT_CREATE]: "contract/create",

  [A.USE_SUMMARY_YYYYMM]: "use-summary/yyyymm",
  [A.USE_SUMMARIES_BY_CONTRACT]: "use-summary/list/by-contract",

  [A.LOGIN_USERS]: "users",
  [A.LOGIN_USER_CREATE]: "user/create",
  [A.LOGIN_USER_CHANGE_PASSWORD]: "user/change-password",

  // FOR CUSTOM
  [A.HT_CUSTOM_CUSTOMERS]: "custom/hello-techno/customers",
  [A.HT_CUSTOM_PARKINGS]: "custom/hello-techno/parkings",
  [A.HT_CUSTOM_PARKING_BY_CODE]: "custom/hello-techno/parking/by-code",
  [A.HT_CUSTOM_ADJUST_DATA]: "custom/hello-techno/adjust-data",
  [A.HT_CUSTOM_RECEIPT_ISSUING_ORDERS]:
    "custom/hello-techno/receipt-issuing-orders",
  [A.HT_CUSTOM_RECEIPT_ISSUING_ORDER_CREATE]:
    "custom/hello-techno/receipt-issuing-order/create",
  [A.HT_CUSTOM_USE_SUMMARIES]: "custom/hello-techno/use-summaries",
  [A.HT_CUSTOM_USE_SUMMARY_DOWNLOAD_CSV]: "custom/hello-techno/use-summary/csv",
  [A.HT_CUSTOM_DOWNLOAD_RECEIPT_LETTER]:
    "custom/hello-techno/receipt-letter/download",
  [A.HT_CUSTOM_DOWNLOAD_RECEIPT_LETTERS]:
    "custom/hello-techno/receipt-letters/download",
};

const prefixs = {
  [A.CSRF_TOKEN]: "",
  [A.DOWNLOAD_RECEIPT]: "",
  [A.DOWNLOAD_RECEIPT_LETTER]: "",
  [A.HT_CUSTOM_USE_SUMMARY_DOWNLOAD_CSV]: "",
  [A.HT_CUSTOM_DOWNLOAD_RECEIPT_LETTER]: "",
  [A.HT_CUSTOM_DOWNLOAD_RECEIPT_LETTERS]: "",
};
const DEFAULT_API_URL_PREFIX = "api";

const getPrefix = (apiId: A) => {
  return prefixs[apiId] ?? DEFAULT_API_URL_PREFIX;
};

export const getUrl = (apiId: A) => {
  let url = getPrefix(apiId);
  if (url.length !== 0) {
    url += "/";
  }
  return url + (urls[apiId] ?? "");
};

export const getFullUrl = (apiId: A) => {
  return HOST_API + "/" + getUrl(apiId);
};
