import { PageID } from "codes/page";
import useAuth from "hooks/useAuth";
import DashboardLayout from "layouts/dashbord";
import { lazy, useMemo } from "react";
import { RouteObject } from "react-router-dom";
import { Loadable } from "routes";
import { getRoute } from "routes/path";

export default function DashboardRoutes(): RouteObject {
  const { canAccess } = useAuth();

  const Dashboard = Loadable(lazy(() => import("pages/dashboard")));
  // 契約関連
  const ContractList = Loadable(
    lazy(() => import("pages/dashboard/contract/list"))
  );
  const ContractDetail = Loadable(
    lazy(() => import("pages/dashboard/contract/detail"))
  );
  const ContractCreate = Loadable(
    lazy(() => import("pages/dashboard/contract/create"))
  );
  // 領収証発行依頼
  const ReceiptIssuingOrderCreateHelloTechno = Loadable(
    lazy(
      () =>
        import(
          "pages/dashboard/receipt-issuing-order/custom/hello-techno/create"
        )
    )
  );
  const ReceiptIssuingOrderListHelloTechno = Loadable(
    lazy(
      () =>
        import("pages/dashboard/receipt-issuing-order/custom/hello-techno/list")
    )
  );
  const ReceiptIssuingOrderDetailHelloTechno = Loadable(
    lazy(
      () =>
        import(
          "pages/dashboard/receipt-issuing-order/custom/hello-techno/detail"
        )
    )
  );

  // 利用実績関連
  const UseSummaryByContractList = Loadable(
    lazy(() => import("pages/dashboard/use-summary/list"))
  );
  const UseSummaryListHelloTechno = Loadable(
    lazy(() => import("pages/dashboard/use-summary/custom/hello-techno/list"))
  );
  const UseSummaryDetailHelloTechno = Loadable(
    lazy(() => import("pages/dashboard/use-summary/custom/hello-techno/detail"))
  );

  //　ログインユーザー管理
  const LoginUserList = Loadable(
    lazy(() => import("pages/dashboard/login-user/list"))
  );
  const LoginUserCreate = Loadable(
    lazy(() => import("pages/dashboard/login-user/create"))
  );
  const ChangePassword = Loadable(
    lazy(() => import("pages/dashboard/login-user/change-password"))
  );

  const children: RouteObject[] = useMemo(() => {
    const allChildren = [
      {
        pageId: PageID.DASHBOARD_OVERVIEW,
        element: <Dashboard />,
      },
      {
        pageId: PageID.DASHBOARD_CONTRACT_LIST,
        element: <ContractList />,
      },
      {
        pageId: PageID.DASHBOARD_CONTRACT_DETAIL,
        element: <ContractDetail />,
      },
      {
        pageId: PageID.DASHBOARD_CONTRACT_CREATE,
        element: <ContractCreate />,
      },
      {
        pageId: PageID.DASHBOARD_USE_SUMMARY_LIST_BY_CONTRACT,
        element: <UseSummaryByContractList />,
      },
      {
        pageId:
          PageID.DASHBOARD_RECEIPT_ISSUING_ORDER_CREATE_CUSTOM_HELLO_TECHNO,
        element: <ReceiptIssuingOrderCreateHelloTechno />,
      },
      {
        pageId: PageID.DASHBOARD_RECEIPT_ISSUING_ORDER_LIST_CUSTOM_HELLO_TECHNO,
        element: <ReceiptIssuingOrderListHelloTechno />,
      },
      {
        pageId:
          PageID.DASHBOARD_RECEIPT_ISSUING_ORDER_DETAIL_CUSTOM_HELLO_TECHNO,
        element: <ReceiptIssuingOrderDetailHelloTechno />,
      },
      {
        pageId: PageID.DASHBOARD_USE_SUMMARY_LIST_CUSTOM_HELLO_TECHNO,
        element: <UseSummaryListHelloTechno />,
      },
      {
        pageId: PageID.DASHBOARD_USE_SUMMARY_DETAIL_CUSTOM_HELLO_TECHNO,
        element: <UseSummaryDetailHelloTechno />,
      },
      {
        pageId: PageID.DASHBOARD_LOGIN_USER_LIST,
        element: <LoginUserList />,
      },
      {
        pageId: PageID.DASHBOARD_LOGIN_USER_CREATE,
        element: <LoginUserCreate />,
      },
      {
        pageId: PageID.DASHBOARD_LOGIN_USER_CHANGE_PASSWORD,
        element: <ChangePassword />,
      },
    ];
    return allChildren
      .filter(({ pageId }) => canAccess(pageId))
      .map(({ pageId, ...others }) => ({
        ...others,
        path: getRoute(pageId),
      }));
  }, [canAccess]);

  return {
    element: <DashboardLayout />,
    children,
  };
}
